/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RfiRequestService} from 'src/app/services/rfi/rfi-request.service';
import {
	ConfirmationService,
	MenuItem,
	MessageService,
	SelectItem
} from 'primeng/api';
import {RfiLookupRequestService} from 'src/app/services/rfi/rfi-lookup-request.service';
import {RfiCostRequestService} from 'src/app/services/rfi/rfi-cost-request.service';
import {RfiQuestionLookupRequestService} from 'src/app/services/rfi/rfi-question-lookup-request.service';
import {RfiQuestionRequestService} from 'src/app/services/rfi/rfi-question-request.service';
import moment from 'moment';
import {RfiToolingRequestService} from 'src/app/services/rfi/rfi-tooling-request.service';
import {RfiThresholdRequestService} from 'src/app/services/rfi/rfi-threshold-request.service';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';
import {RfiHistoryRequestService} from 'src/app/services/rfi/rfi-history-request.service';
import {PdfGenerator} from './pdf-generator';
import {ManualRfiRequestService} from '../../services/rfi/manual-rfi-request.service';
import {RFIDocumentService} from 'src/app/services/rfi/rfi-document.service';
import {ExcelGenerator} from './excel-generator';

@Component({
	selector: 'create-rfi-request',
	templateUrl: './create-rfi-request.component.html',
	styleUrls: ['./create-rfi-request.component.css'],
	providers: [
		MessageService,
		ConfirmationService,
		PdfGenerator,
		ExcelGenerator
	]
})
export class CreateRfiRequestComponent implements OnInit {
	yesNoType: SelectItem[] = [
		{title: 'Yes', label: 'Yes', value: 'Yes', icon: 'fa fa-check'},
		{title: 'No', label: 'No', value: 'No', icon: 'fa fa-times'}
	];
	modeType: SelectItem[] = [
		/*{title: 'View', label: 'View', value: 'View', icon: 'fa fa-eye'}*/
	];
	selectedMode = 'View';
	cols: any[];
	toolingRecords: any[] = [];
	toolings: any[] = [];
	tooling = {};
	selectedTooling = {};
	displayDialog = false;
	detailDialog = false;
	dialogDetails = null;
	newTooling = false;
	toolingTotal = 0;
	weightRFIHeader;
	isPartWeightImp = '';
	subComTotPrevRFI = 0;
	subComTotCurRFI = 0;
	subMatTotPrevRFI = 0;
	subMatTotCurRFI = 0;
	prevSubComTotCurRFI = 0;
	prevSubMatTotCurRFI = 0;
	prevSubComTotPrevRFI = 0;
	prevSubMatTotPrevRFI = 0;
	prevToolingCost = 0;
	prevLumpSumCost = 0;
	rfiHistory = {};
	rfiHistoryList = [];
	rfiHistoryInternalCostList = [];
	costChange = false;
	private mode: string;
	buyerSupplier: string;
	private rfiId: string;
	rfiRequest: any = {};
	private materialCosts = [];
	private clonedMaterial: {[s: string]: any} = {};
	private lookups = [];
	private questionLookups = [];
	private totPrevRFI = 0;
	private totCurvRFI = 0;
	private isLoader = false;
	private rfiQuestions = [];
	private clonedRfiQuestion: {[s: string]: any} = {};
	private tabIndex = 0;
	private rfiRequestForm: FormGroup;
	beginRFI = false;
	showCalculation = false;
	private reSubmitRFI = false;
	private submitToPDRFI = false;
	private commercialCosts = [];
	private clonedCommercial: {[s: string]: any} = {};
	private prevToolingRFILable;
	private prevRFILable;
	// tslint:disable-next-line:indent
	private prevWeightRFILable;
	private prevLumpSumRFILable;
	private supplierRFILumpSum;
	private curretRFIHeader;
	private toolingRFIHeader;
	private deltaRFIHeader =
		'Delta \n\r(Positive[+] Delta = Increase)\n\r(Negative[-] Delta = Decrease)';
	private deltaLumpSumHeader =
		'Delta \n\r(Positive[+] Delta = Increase)\n\r(Negative[-] Delta = Decrease)';
	private costtool =
		'Delta \n\r(Positive[+] Delta = Increase)\n\r(Negative[-] Delta = Decrease)';
	private lumSumTrue = true;
	private lumSumFalse = false;
	private timer;
	negativeTimer = false;
	statusColor = 'black';
	private status;
	private program;
	breadCrumItems: MenuItem[];
	private isUnselectClick = false;
	private isPdEngg = false;
	private searchRfi = 'false';
	private selectedType = 'RFQ';
	private back = 'false';
	private isToolingWholeOption = 'true';
	private isProdToolingWarn = false;
	attachmentsList = [];
	private buddyBuyerMap = {};
	isValidBuyer = false;
	private validationMessage = '';
	rfqText = '';
	interanlCostDetailsEdit = false;
	showInteranlCostDetailsEdit = true;
	documentList = [];
	documentIds = [];
	uploadedFiles: any[] = [];
	filetype =
		'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/*';
	maxFileSize = 1000000000;
	contents: any = null;
	filename: string;
	isPiecePriceEstimateSaved = false;
	isProductionToolingEstimateSaved = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private rfiRequestService: RfiRequestService,
		private rfiToolingRequestService: RfiToolingRequestService,
		private rfiLookupRequestService: RfiLookupRequestService,
		private rfiCostRequestService: RfiCostRequestService,
		private rfiHistoryRequestService: RfiHistoryRequestService,
		private rfiQuestionLookupRequestService: RfiQuestionLookupRequestService,
		private rfiQuestionRequestService: RfiQuestionRequestService,
		private rfiThreasholdService: RfiThresholdRequestService,
		private router: Router,
		private historyService: RfiHistoryRequestService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private fb: FormBuilder,
		private pdfGenerator: PdfGenerator,
		private _sanitizer: DomSanitizer,
		private manualRfiRequestService: ManualRfiRequestService,
		private rfiDocumentService: RFIDocumentService,
		private excelGenerator: ExcelGenerator
	) {
		this.cols = [
			{field: 'name', header: 'Tooling Item'},
			{field: 'cost', header: 'Cost'},
			{field: 'additionalInfo', header: 'Additional Info'}
		];

		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles && apsRoles.includes('PD_Engineer')) {
			this.isPdEngg = true;
		}

		this.retrieveRouteParameter();
		if (
			!(
				apsRoles &&
				((this.buyerSupplier === 'supplier' &&
					apsRoles.includes('EDCM_UPDATE')) ||
					(this.buyerSupplier === 'buyer' &&
						apsRoles.includes('PD_Engineer')) ||
					(this.buyerSupplier === 'buyer' &&
						apsRoles.includes('Buyer')) ||
					(this.buyerSupplier === 'buyer' &&
						apsRoles.includes('CostEstimator')) ||
					(this.buyerSupplier === 'buyer' &&
						apsRoles.includes('JV Buyer')) ||
					(this.buyerSupplier === 'buyer' &&
						apsRoles.includes('JV Engineer')) ||
					(this.buyerSupplier === 'buyer' &&
						apsRoles.includes('JV Costestimator')) ||
					apsRoles.includes('eDCM_IT_ADMIN') ||
					apsRoles.includes('eDCM_Super_Buyer') ||
					apsRoles.includes('EDCM_VIEW_ONLY'))
			)
		) {
			this.router.navigate(['/permission-denied']);
		}
	}

	onRowDelete(data) {
		const docIndex = this.documentList.findIndex(
			(doc) => doc.fileName === data.fileName
		);
		const fileIndex = this.uploadedFiles.findIndex(
			(file) => file.name === data.fileName
		);
		if (fileIndex > -1) {
			this.uploadedFiles.splice(fileIndex, 1);
		}
		if (docIndex > -1) {
			this.documentList.splice(docIndex, 1);
		}

		if (data.id != null) {
			this.documentIds.push(data.id);
		}

		// if (docIndex > -1) {
		// 	this.messageService.add({
		// 		severity: 'success',
		// 		summary: 'File deleted successfully',
		// 		detail: `${data.fileName} has been removed`
		// 	});
		// }
	}

	private getDocumentList() {
		this.rfiDocumentService
			.showDocumentList(this.rfiRequest.rfiPrefix, this.rfiRequest.rfiNum)
			.subscribe((response) => {
				this.documentList = response;
			});
	}

	onFileUpload(event, fileUploader) {
		for (const file of event.files) {
			const fileSizeInMB = file.size / (1024 * 1024);
			if (fileSizeInMB > 9) {
				this.messageService.add({
					severity: 'warn',
					summary:
						'The file size exceeds the permitted limit of 9 MB. Please reduce the file size before attempting to upload',
					detail: ''
				});

				fileUploader.clear();

				return;
			}
			this.uploadedFiles.push(file);
			this.documentList.push({id: null, fileName: file.name});
			// this.messageService.add({
			// 	severity: 'success',
			// 	summary: 'File Upload Successful',
			// 	detail: ''
			// });
		}

		// Clear the uploader after all valid files are processed
		fileUploader.clear();
	}

	uploadDocument() {
		if (this.buyerSupplier === 'buyer') {
			return;
		}
		const formData = new FormData();

		formData.append('documentIds', this.documentIds.toString());

		// Append all uploaded files to FormData
		this.uploadedFiles.forEach((file, index) => {
			formData.append('files', file, file.name); // Use 'files' as the field name
		});

		if (
			this.uploadedFiles.length === 0 &&
			this.documentList.length !== 0 &&
			this.documentIds.length === 0
		) {
			return;
		}

		this.rfiDocumentService
			.uploadDocument(
				formData,
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			)
			.subscribe(
				() => {
					this.getDocumentList();
				},
				(error) => {
					console.error(error);
					this.messageService.add({
						severity: 'error',
						summary: 'Upload failed',
						detail: ''
					});
				}
			);
	}

	getLumpSumHeader() {
		return this.deltaLumpSumHeader;
	}

	showDialogToAdd() {
		this.newTooling = true;
		this.tooling = {};
		this.displayDialog = true;
	}

	validateToolingCost() {
		this.isProdToolingWarn = false;
		if (!this.rfiRequest.rfiCost || this.rfiRequest.rfiCost === 0) {
			this.isProdToolingWarn = true;
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					detail:
						'RFI/RFQ Cost for Production Tooling equals zero (0),' +
						' all previously approved Production Tooling will be removed.'
				});
			}, 30000);
		}
	}

	isShowVersionBanner() {
		return this.rfiRequest.rfiVersion > 0;
	}

	getVersionBanner() {
		return `Version #${this.rfiRequest.rfiVersion}`;
	}

	isShowTimeDisClaimer() {
		const isSupplier = this.buyerSupplier === 'supplier';
		return (
			isSupplier &&
			(this.rfiRequest.statusId === 1 ||
				this.rfiRequest.statusId === 2 ||
				this.rfiRequest.statusId === 5 ||
				this.rfiRequest.statusId === 6)
		);
	}

	showDetails(details) {
		this.detailDialog = true;
		this.dialogDetails = details;
	}

	delete(data) {
		this.confirmationService.confirm({
			message: 'Are you sure you want to Delete Tooling Breakdown',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				const index = this.toolingRecords.indexOf(data);
				this.toolingRecords = this.toolingRecords.filter(
					(val, i) => i !== index
				);
				this.setTotalTooling(this.toolingRecords);

				if (this.toolingRecords.length === 0) {
					this.isToolingWholeOption = 'true';
				}
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'success',
						summary: 'Tooling Breakdown Item Deleted'
					});
				}, 30);
			},
			reject: () => {
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary:
							'Delete Operation Not Able to Perform Please Check.'
					});
				}, 30);
			}
		});
	}

	onToolingClose() {
		this.displayDialog = false;
	}

	save(data) {
		if (!data.cost || data.cost === undefined) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Tooling cost must be filled.'
				});
			}, 30);

			return;
		}
		const toolings = [...this.toolingRecords];
		if (this.newTooling) {
			toolings.push(data);
		} else {
			toolings[this.toolingRecords.indexOf(this.selectedTooling)] = data;
		}

		if (this.setTotalTooling(toolings)) {
			this.toolingRecords = toolings;
			this.tooling = null;
			this.displayDialog = false;
		}
	}

	onRowSelect(data) {
		this.newTooling = false;
		this.tooling = this.cloneTooling(data);
		this.selectedTooling = data;
		this.displayDialog = true;
		this.setTotalTooling(this.tooling);
	}

	cloneTooling(tooling) {
		const newTooling = {};
		// tslint:disable-next-line:forin
		// eslint-disable-next-line guard-for-in
		for (const prop in tooling) {
			newTooling[prop] = tooling[prop];
		}
		return newTooling;
	}

	isLumpSumpEdit(rowData) {
		if (
			!this.isUnselectClick &&
			!rowData.lumpSum &&
			!rowData.lsIncludeFIn
		) {
			rowData.lumpSum = rowData.prevLumpSum;
		}
		return (
			this.isEditMode() &&
			((!rowData.lumpSum && !rowData.lsIncludeFIn) ||
				!rowData.lsIncludeFIn)
		);
	}

	isLSINIncludeFINEdit(rowData) {
		if (
			!this.isUnselectClick &&
			!rowData.lumpSum &&
			!rowData.lsIncludeFIn
		) {
			rowData.lsIncludeFIn = rowData.prevLsIncludeFIn;
		}
		return (
			this.isEditMode() &&
			((!rowData.lumpSum && !rowData.lsIncludeFIn) || !rowData.lumpSum)
		);
	}

	loadToolingItems() {
		this.isToolingWholeOption = 'false';
		this.retriveTooling(this.rfiRequest.rfiNum);
	}

	resetToolingItems() {
		this.isToolingWholeOption = 'true';
		this.toolingRecords = [];
		console.log(this.toolingRecords);
	}

	get4Digit(cost) {
		// Convert string input to number if necessary
		const numericCost = typeof cost === 'string' ? parseFloat(cost) : cost;

		// Check if the conversion was successful or if it's a valid number
		if (cost === null || isNaN(numericCost)) {
			return '0.0000';
		}

		// Format the number to 5 decimal places and return it
		let amount = numericCost.toFixed(5);
		amount = parseFloat(amount).toLocaleString('en-US');

		return amount;
	}
	get4DigitFixedPiecePrice(cost) {
		return cost ? cost.toFixed(5) : cost;
	}
	get4DigitFixed(cost) {
		// Convert string input to number if necessary
		const numericCost = typeof cost === 'string' ? parseFloat(cost) : cost;

		// Check if the conversion was successful or if it's a valid number
		if (cost === null || isNaN(numericCost)) {
			return '0.00000';
		}

		// Format the number to 5 decimal places and return it
		let amount = numericCost.toFixed(5);
		amount = parseFloat(amount).toLocaleString('en-US', {
			minimumFractionDigits: 5,
			maximumFractionDigits: 5
		});

		return amount;
	}

	getRFIHistoryList(rfiPrefix, rfiId) {
		this.historyService.findAll(rfiPrefix, rfiId).subscribe((response) => {
			this.rfiHistoryList = response;
		});
	}
	getRFIHistoryInternalCostList(rfiPrefix, rfiId) {
		this.historyService
			.findAllInternalCost(rfiPrefix, rfiId)
			.subscribe((response) => {
				this.rfiHistoryInternalCostList = response;
			});
	}

	fetchRFIHeaderData() {
		this.rfiRequestService.findById(this.rfiId).subscribe((response) => {
			this.rfiRequest = response;
			this.rfiRequest.internalComment = response.internalComment;
			this.rfiRequest.isRfq = response.isRfq;
			this.rfiRequest.proposedCostMaturity =
				response.proposedCostMaturity;
			this.getDocumentList();
			if (this.rfiRequest.isRfq) {
				this.rfqText = 'RFQ';
			} else {
				this.rfqText = 'RFI';
			}
			if (this.buyerSupplier === 'buyer') {
				this.validationCheck();
			}
			this.prevWeightRFILable = 'Current FEDEBOM/e3P Weight';
			this.weightRFIHeader = 'Supplier’s New Total Weight';
			if (
				this.rfiRequest.rfiPrevWeightUnit &&
				this.rfiRequest.rfiPrevWeightUnit !== 'null'
			) {
				this.prevWeightRFILable =
					'Current FEDEBOM/e3P Weight\n\r(' +
					this.rfiRequest.rfiPrevWeightUnit +
					')';
			}
			if (
				this.rfiRequest.weightUnit &&
				this.rfiRequest.weightUnit !== 'null'
			) {
				this.weightRFIHeader =
					'Supplier’s New Total Weight \n\r' +
					'(' +
					this.rfiRequest.weightUnit +
					')';
			}
			this.prevToolingRFILable =
				'Previous RFI/RFQ Production Tooling\n\r';
			this.prevToolingRFILable += this.rfiRequest.prevRfiId
				? '(' +
					this.rfiRequest.prevRfiId +
					')(' +
					this.rfiRequest.resCur +
					')'
				: '(' + this.rfiRequest.resCur + ')';
			this.prevRFILable = 'Previous RFI/RFQ Cost\n\r';
			this.prevRFILable += this.rfiRequest.prevRfiId
				? '(' +
					this.rfiRequest.prevRfiId +
					')(' +
					this.rfiRequest.resCur +
					')'
				: '(' + this.rfiRequest.resCur + ')';
			this.prevLumpSumRFILable = 'Previous RFI/RFQ Lump Sum ED&T\n\r';
			this.prevLumpSumRFILable += this.rfiRequest.prevRfiId
				? '(' + this.rfiRequest.prevRfiId + ')'
				: '(' + this.rfiRequest.resCur + ')';
			this.supplierRFILumpSum =
				'RFI/RFQ Cost\n\r (' + this.rfiRequest.resCur + ')';
			this.curretRFIHeader =
				'RFI/RFQ Cost\n\r (' + this.rfiRequest.resCur + ')';
			this.toolingRFIHeader =
				'Supplier’s New Total Tooling \n\r(' +
				this.rfiRequest.resCur +
				')';
			this.attachmentsList =
				this.rfiRequest.enggDetails != null &&
				this.rfiRequest.enggDetails !== '' &&
				this.rfiRequest.enggDetails !== ' '
					? this.rfiRequest.enggDetails.split('|')
					: [];

			this.isBeginRFI(this.rfiRequest);
			this.isSubmittedToPD(this.rfiRequest);
			this.isReSubmitRFI(this.rfiRequest);

			this.getRFIHistoryList(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			);
			this.getRFIHistoryInternalCostList(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			);
			if (this.isShowReqAddTime()) {
				this.calculateTimerTime(this.rfiRequest);
			}

			if (this.isEditRFI() && !this.negativeTimer) {
				this.updateModType();
			}

			this.retriveQuestions(this.rfiRequest.rfiNum);
			this.retriveAllCosts(this.rfiRequest.rfiNum);
			this.prevToolingCost = this.getToolingCost();
			this.retriveTooling(this.rfiRequest.rfiNum);
			if (this.rfiRequest.weightImapactFlag != null) {
				this.retriveWeight();
			}

			setInterval(() => {
				if (this.isShowReqAddTime()) {
					this.calculateTimerTime(this.rfiRequest);
				}
			}, 1000);

			this.rfiRequest.partCostTarget = this.get4DigitFixed(
				this.rfiRequest.partCostTarget
			);
			this.rfiRequest.pdMatCostEstimate = this.get4DigitFixed(
				this.rfiRequest.pdMatCostEstimate
			);
			this.rfiRequest.e3pMatCostEstimate = this.get4DigitFixedPiecePrice(
				this.rfiRequest.e3pMatCostEstimate
			);
			this.rfiRequest.rfiPrevCost = this.get4DigitFixedPiecePrice(
				this.rfiRequest.rfiPrevCost
			);
			this.isPiecePriceEstimateSaved = response.isproposedLocalCost;
			this.isProductionToolingEstimateSaved = response.isproposedToolCost;
			//this.rfiRequest.toolingCostTarget =
			//this.rfiRequest.toolingCostTarget;
			//this.rfiRequest.pdToolingEstimate =
			//this.rfiRequest.pdToolingEstimate;
			//this.rfiRequest.e3pToolingEstimate =
			//	this.rfiRequest.e3pToolingEstimate;
			if (response.e3pToolingEstimate == 0.0) {
				this.rfiRequest.e3pToolingEstimate = response.pdToolingEstimate;
				this.rfiRequest.e3pToolingEstimateCur =
					response.pdToolingEstimateCur;
			}
			if (response.e3pMatCostEstimate == 0.0) {
				this.rfiRequest.e3pMatCostEstimate = response.pdMatCostEstimate;
				this.rfiRequest.e3pMatCostEstimateCur =
					response.pdMatCostEstimateCur;
			}
			const buyerSupplier =
				this.buyerSupplier.toLowerCase() === 'buyer'
					? 'Buyer Home Page'
					: 'Supplier Home Page';
			let statusString =
				this.status === 'U'
					? 'Unread RFIS'
					: this.status === 'I'
						? 'InProgress RFIs'
						: 'Completed RFIs';
			if (this.status && this.status.includes('SelectRFI')) {
				statusString =
					this.buyerSupplier.toLowerCase() === 'buyer'
						? 'Manage RFIs/RFQs'
						: 'Manage RFIs/RFQs';
			}
			const program = this.program;

			const buyerSupplierUrl =
				this.buyerSupplier.toLowerCase() === 'buyer'
					? 'buyer'
					: 'supplier';

			if (program && program !== 'null') {
				// tslint:disable-next-line:triple-equals
				statusString =
					this.status === 'U'
						? 'PIE Unread RFIS'
						: this.status === 'I'
							? 'PIE InProgress RFIs'
							: 'PIE Completed RFIs';
			}

			let statusUrl =
				'#/rfi-request/' +
				buyerSupplierUrl +
				'/program/' +
				program +
				'/' +
				this.status;
			if (this.status && this.status.includes('SelectRFI')) {
				const supp =
					this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
				statusUrl =
					'#/rfi-request/' +
					this.buyerSupplier +
					'/all/all/' +
					supp +
					'SelectRFI' +
					'false/false';
			}

			this.breadCrumItems = [
				{
					label: buyerSupplier,
					url: '#/buyer-supplier/' + buyerSupplierUrl,
					target: '_self'
				},
				{
					label: statusString,
					url: this.getBackNavUrl(),
					target: '_self'
				},
				{label: this.rfiRequest.rfiId}
			];

			if (
				program &&
				program !== 'null' &&
				!this.status.includes('SelectRFI')
			) {
				if (statusString === 'PIE Completed RFIs') {
					this.breadCrumItems.splice(2, 0, {
						label: this.status,
						url: statusUrl
					});
					this.breadCrumItems.splice(3, 0, {
						label: program,
						url: statusUrl
					});
				} else {
					this.breadCrumItems.splice(2, 0, {
						label: program,
						url: statusUrl
					});
				}
			}
		});
	}

	updateModType() {
		if (this.isSupplierOwnRfi()) {
			this.modeType = [
				/*{title: 'View', label: 'View', value: 'View', icon: 'fa fa-eye'},*/
				{
					title: 'Edit',
					label: 'Edit',
					value: 'Edit',
					icon: 'fa fa-edit'
				}
			];
		}
	}

	initializeFormValidation(questions) {
		const formObj = {};

		for (const ques of questions) {
			formObj[ques.lookupId + 'question'] = new FormControl(
				{disabled: true},
				Validators.required
			);
		}

		this.rfiRequestForm = this.fb.group(formObj);
	}

	ngOnInit(): void {
		this.retrieveRouteParameter();
	}

	loadRFIData() {
		if (this.mode === 'edit') {
			this.isLoader = true;
			this.fetchRFIHeaderData();

			console.log(this.rfiRequest);
			console.log(this.materialCosts);
		} else {
			this.rfiRequest = null;
			this.rfiRequest = Object.assign({}, emptyRfiRequest);
		}
	}

	goToBuyer() {
		this.router.navigate([
			'/rfi-request/' + this.buyerSupplier + '/all/all/null'
		]);
	}

	changeView(value) {
		console.log(this.selectedMode);
		console.log(value);
		if (
			this.selectedMode === 'Edit' &&
			![8, 9, 10, 13, 17].includes(this.rfiRequest.statusId)
		) {
			this.updateSupplierStatus();
		}
	}

	onRowEditInit(cost, cloned) {
		cloned[cost.lookupId] = cost;
	}

	onRowEditSave() {
		this.updateTotalCost(false);
	}

	displayMessage(message) {
		setTimeout(() => {
			this.messageService.add({
				life: 300000,
				severity: 'success',
				summary: message
			});
		}, 30);
	}

	onRowEditCancel(cost, costs, cloned, index: number) {
		costs[index] = cloned[cost.lookupId];
		delete cloned[cost.lookupId];
	}

	getHistoryCosts() {
		const pieceDelta = this.subMatTotCurRFI - this.subMatTotPrevRFI;
		const comDelta = this.subComTotCurRFI - this.subComTotPrevRFI;
		const toolDelta = this.getToolingCost() - this.rfiRequest.rfiPrevCost;
		let lumpSumCost = 0;
		let prevRfiLumpSumA = 0;
		for (const result of this.commercialCosts) {
			if (result.costBucket === 'ED&T paid in Piece Price') {
				lumpSumCost = result.lumpSumA;
				prevRfiLumpSumA = result.prevLumpSumA;
			}
		}

		const lumpSumDelta = lumpSumCost - prevRfiLumpSumA;

		const historyCosts = {
			pieceCost: this.subMatTotCurRFI,
			pieceDelta,
			comCost: this.subComTotCurRFI,
			comDelta,
			toolCost: this.getToolingCost(),
			toolDelta,
			lumpSumCost,
			lumpSumDelta
		};

		return {
			rfiPrefix: this.rfiRequest.rfiPrefix,
			rfiId: this.rfiRequest.rfiNum,
			piecePrice: historyCosts.pieceCost,
			piecePriceDelta: historyCosts.pieceDelta,
			commPrice: historyCosts.comCost,
			commPriceDelta: historyCosts.comDelta,
			toolingPrice: parseInt(historyCosts.toolCost.toString(), 10),
			toolingPriceDelta: historyCosts.toolDelta,
			lumpSumPrice: parseFloat(historyCosts.lumpSumCost.toString()),
			lumSumPriceDelta: historyCosts.lumpSumDelta,
			partPrefix: this.rfiRequest.partPrefix,
			partBase: this.rfiRequest.partBase,
			partSuffix: this.rfiRequest.partSuffix,
			plant: this.rfiRequest.plantCode,
			version: this.rfiRequest.rfiVersion
		};
	}

	onSave() {
		if (this.isEditMode()) {
			const materialSubTotalCost = this.materialCosts.filter(
				(cost) => cost.isTotal
			);
			// tslint:disable-next-line:triple-equals
			if (
				materialSubTotalCost[0] &&
				materialSubTotalCost[0].curRFI === 0
			) {
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary:
							'The RFI/RFQ Cost Subtotal cannot equal zero (0). ' +
							'If the values in the Cost Breakdown total zero (0), all previously approved Piece Price will be removed.  '
					});
				}, 30);

				return;
			}

			this.saveCosts();
			this.saveToolings();
			this.updateRFI();
			this.uploadDocument();
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'success',
					summary: 'RFI/RFQ saved successfully'
				});
			}, 30);
			this.selectedMode = 'Edit';
		}
		this.selectedMode = 'Edit';
	}

	getTotalTooling() {
		this.toolingTotal = 0;
		this.toolingRecords.forEach((tool) => {
			this.toolingTotal += Number(tool.cost);
		});
	}

	setTotalTooling(toolings) {
		let toolingTotal = 0;
		if (Array.isArray(toolings)) {
			toolings.forEach((tool) => {
				toolingTotal += Number(tool.cost);
			});
		}
		const rfiCost = this.getRFIAdd(
			this.rfiRequest.rfiPrevCost,
			toolingTotal
		);
		if (Math.sign(Number(rfiCost)) === -1) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary:
						'The Production Tooling RFI/RFQ Cost cannot be negative. Please check the entered value(s).'
				});
			}, 30);
			return false;
		} else {
			this.toolingTotal = toolingTotal;
		}

		return true;
	}

	getBackNavUrl() {
		let program = this.program;
		let statusValue = this.status;
		if (program === undefined) {
			program = 'all';
		}
		if (statusValue === undefined) {
			statusValue = 'SelectRFI';
		}

		let back = 'true'; //this.searchRfi === 'false' ? false : true;
		if (this.searchRfi === 'false' && this.buyerSupplier === 'buyer') {
			back = 'true';
		}
		sessionStorage.setItem('back', back);

		let statusUrl =
			'/#/rfi-request/' +
			this.buyerSupplier +
			'/programs/' +
			program +
			'/' +
			statusValue +
			'/' +
			back +
			'/' +
			this.searchRfi +
			'/' +
			this.selectedType;
		if (statusValue && statusValue.includes('SelectRFI')) {
			const supp = this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
			statusUrl =
				'/#/rfi-request/' +
				this.buyerSupplier +
				'/all/all/' +
				supp +
				'SelectRFI' +
				'/' +
				back +
				'/' +
				this.searchRfi +
				'/' +
				this.selectedType;
		}
		return statusUrl;
	}

	getStatusUrl() {
		let program = this.program;
		let statusValue = this.status;
		if (program === undefined) {
			program = 'all';
		}
		if (statusValue === undefined) {
			statusValue = 'SelectRFI';
		}

		let back = 'true'; //this.searchRfi === 'false' ? false : true;
		if (this.searchRfi === 'false' && this.buyerSupplier === 'buyer') {
			back = 'true';
		}
		sessionStorage.setItem('back', back);

		let statusUrl =
			'/rfi-request/' +
			this.buyerSupplier +
			'/programs/' +
			program +
			'/' +
			statusValue +
			'/' +
			back +
			'/' +
			this.searchRfi +
			'/' +
			this.selectedType;
		if (statusValue && statusValue.includes('SelectRFI')) {
			const supp = this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
			statusUrl =
				'/rfi-request/' +
				this.buyerSupplier +
				'/all/all/' +
				supp +
				'SelectRFI' +
				'/' +
				back +
				'/' +
				this.searchRfi +
				'/' +
				this.selectedType;
		}
		this.router.navigate([statusUrl]);
	}

	onCancel() {
		this.confirmationService.confirm({
			message:
				'You have Unsaved work and closing will lose your data, are you sure you want to "Close" before saving ',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.getStatusUrl();
			},
			reject: () => {
				//do nothing
			}
		});
	}

	onReset() {
		this.loadRFIData();
	}

	onSubmit() {
		if (this.rfiRequest.rfiCost === '' && this.toolingTotal === 0) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'Supplier New Tooling field must contain a value (may be zero).'
			});
			return;
		}

		this.confirmationService.confirm({
			message: 'Are you sure you want to "Submit RFI/RFQ" data',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				const isQuestiosnFilled = this.isAllFieldsFilled(
					this.rfiQuestions,
					'Questions'
				);
				//const isLumpSumFilled = this.isAllFieldsFilled(null, 'LumpSum');
				const isTollingFilled = this.isAllFieldsFilled(
					this.rfiQuestions,
					'Toolings'
				);
				const materialSubTotalCost = this.materialCosts.filter(
					(cost) => cost.isTotal
				);

				if (
					materialSubTotalCost[0] &&
					materialSubTotalCost[0].curRFI === 0
				) {
					setTimeout(() => {
						this.messageService.add({
							life: 300000,
							severity: 'warn',
							summary:
								'The RFI/RFQ Cost Subtotal cannot equal zero (0). If the values in the Cost Breakdown total zero (0), all previously approved Piece Price will be removed.  '
						});
					}, 30);

					return;
				}

				if (isQuestiosnFilled) {
					this.rfiHistory = this.getHistoryCosts();

					this.saveCosts();
					this.uploadDocument();
					this.saveToolings();
					this.onQuestionSave();

					{
						this.rfiHistoryRequestService
							.saveHistory(this.rfiHistory)
							.subscribe((result) => {
								this.prevSubMatTotCurRFI = this.subMatTotCurRFI;
								this.prevSubComTotCurRFI = this.subComTotCurRFI;
								this.prevToolingCost = this.getToolingCost();
								this.costChange = false;
								this.getRFIHistoryList(
									this.rfiRequest.rfiPrefix,
									this.rfiRequest.rfiNum
								);
								console.log(result);
							});
					}

					const program = this.rfiRequest.leadPrg;
					const commodity = this.rfiRequest.purCommCode;
					const psa = this.rfiRequest.psa;
					const userId = 'eDCM_IT_ADMIN';

					console.log(commodity);
					console.log(program);

					this.rfiThreasholdService
						//.findThreshold(program, commodity, userId)
						.findThresholdForPsa(psa, userId)
						.subscribe((threshold) => {
							if (!threshold.mcost) {
								threshold.mcost = 0;
							}

							if (!threshold.tcost) {
								threshold.tcost = 0;
							}

							if (this.isToolingWholeOption === 'true') {
								this.toolingTotal = Number(
									this.rfiRequest.rfiCost
								);
							}

							const costs = this.materialCosts.filter(
								(cost) => cost.isTotal
							);
							const prevCost = costs[0].prevRFI;
							const curCost = costs[0].curRFI;
							const supplierMThreshold =
								curCost === 0
									? 0
									: prevCost +
										(threshold.mcost / 100) * prevCost;
							const prevToolingCost = Number(
								this.rfiRequest.rfiPrevCost
							);
							const toolingThreshold =
								this.toolingTotal === 0
									? 0
									: prevToolingCost +
										(threshold.tcost / 100) *
											prevToolingCost;
							if (this.rfiRequest.isRfq === true) {
								this.updateStatus(
									18,
									'RFI/RFQ successfully Submitted To Buyer Review',
									true
								);
							} else {
								if (
									supplierMThreshold >= curCost &&
									toolingThreshold >= this.toolingTotal &&
									this.rfiRequest.isRfq === false
								) {
									this.updateStatus(
										3,
										'RFI/RFQ response submitted',
										true
									);
								} else {
									this.updateStatus(
										7,
										'RFI/RFQ successfully Submitted To TO4 Review',
										true
									);
								}
							}
						});

					setTimeout(() => {
						this.messageService.add({
							life: 300000,
							severity: 'success',
							summary: 'RFI/RFQ submitted for Review'
						});
					}, 30);
					this.selectedMode = 'View';
				} else {
					let manadatoryWarnMesg = '';
					if (!isTollingFilled) {
						manadatoryWarnMesg +=
							'Please add at least one Tooling Item before submitting RFI/RFQ, even if value is the same as Previous RFI/RFQ Production Tooling';
					}

					if (
						this.isPartWeightImp === 'true' &&
						this.rfiRequest.weightQauntity === 0
					) {
						manadatoryWarnMesg +=
							'Please enter valid weight weight cannot be zero';
					}

					if (this.isPartWeightImp === '') {
						manadatoryWarnMesg +=
							'Please select Part Weight Impcated Option before submitting';
					}
					/*manadatoryWarnMesg += ' ';
					if (!isQuestiosnFilled) {
						manadatoryWarnMesg += 'Please select all answers for questions, ';
					}

					/*if (!isLumpSumFilled) {
						manadatoryWarnMesg += 'Please select if ED&T Includes Financing for either Piece Price or Lump Sum, based on direction from Ford ';
					}*/
					setTimeout(() => {
						this.messageService.add({
							life: 300000,
							severity: 'warn',
							summary: manadatoryWarnMesg
						});
					}, 30);
				}
			},
			reject: () => {
				/*setTimeout(() => {
					that.messageService.add({
						life: 300000, severity: 'warn',
						summary: 'RFI has not been submitted.'
					});
				}, 30000);
				//	that.retriveAllCosts(that.rfiRequest.rfiNum);*/
			}
		});
	}

	updateSupplierStatus() {
		this.rfiRequestService
			.updateSupplierStatus(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			)
			.subscribe((response) => {
				this.rfiRequest.beginSupplier =
					sessionStorage.getItem('emailAddress');
				console.log('updateSupplierStatus updated..');
				console.log(response);
			});
	}

	updateStatus(statusId, message, isUpdateRfi) {
		this.rfiRequestService
			.updateStatusById(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum,
				statusId
			)
			.subscribe((response) => {
				//	this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: message});
				this.rfiRequest.statusId = statusId;

				this.isBeginRFI(this.rfiRequest);
				this.isSubmittedToPD(this.rfiRequest);
				this.isReSubmitRFI(this.rfiRequest);

				if (isUpdateRfi) {
					this.updateRFI();
				}

				if (this.isEditRFI() && !this.negativeTimer) {
					this.updateModType();
				} else {
					this.modeType = [
						/*{title: 'View', label: 'View', value: 'View', icon: 'fa fa-eye'}*/
					];
				}
				if (response.e3pToolingEstimate == 0.0) {
					this.rfiRequest.e3pToolingEstimate =
						response.pdToolingEstimate;
					this.rfiRequest.e3pToolingEstimateCur =
						response.pdToolingEstimateCur;
				}
				if (response.e3pMatCostEstimate == 0.0) {
					this.rfiRequest.e3pMatCostEstimate =
						response.pdMatCostEstimate;
					this.rfiRequest.e3pMatCostEstimateCur =
						response.pdMatCostEstimateCur;
				}
				response.buyerComments = this.rfiRequest.buyerComments;
				response.rfiComments = this.rfiRequest.rfiComments;
				response.pdComments = this.rfiRequest.pdComments;
				response.internalComment = this.rfiRequest.internalComment;
				response.weightComment = this.rfiRequest.weightComment;
				response.weightQauntity = this.rfiRequest.weightQauntity;
				response.weightFlag = this.rfiRequest.weightFlag;
				response.weightImpactFlag = this.rfiRequest.weightImpactFlag;
				response.rfiCost = this.rfiRequest.rfiCost;
				this.rfiRequest = response;
			});
	}

	reSubmitRFIClick() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Resubmit RFI/RFQ"',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.updateStatus(
					5,
					'RFI/RFQ resubmitted back to Supplier',
					true
				);
			},
			reject: () => {
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary: 'RFI/RFQ has not been re-submitted.'
					});
				}, 30000);
			}
		});
	}

	submitToPDClick() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to "Submit to PD"',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				const program = this.rfiRequest.leadPrg;
				const commodity = this.rfiRequest.purCommCode;
				const psa = this.rfiRequest.psa;
				this.updateRFI();

				const userId = 'eDCM_IT_ADMIN';

				this.rfiThreasholdService
					.findThresholdForPsa(psa, userId)
					.subscribe((threshold) => {
						let commodityNonZero = false;
						if (threshold.mcost > 0 || threshold.tcost > 0) {
							commodityNonZero = true;
						}
						if (this.rfiRequest.isRfq) {
							this.updateStatus(
								17,
								'RFI/RFQ submitted to PD',
								true
							);
						} else {
							this.updateStatus(
								3,
								'RFI/RFQ submitted to PD',
								true
							);
						}
						// if (!commodityNonZero) {
						// 	that.updateStatus(3, 'RFI submitted to PD', true);
						// 	// that.confirmationService.confirm({
						// 	// 	message: 'Buyer did not set threshold for program ' + program + ' and commodity ' + commodity + ' so eDCM consider it as 0.0% do you agree this ?',
						// 	// 	header: 'Confirmation',
						// 	// 	icon: 'pi pi-exclamation-triangle',
						// 	// 	accept: () => {
						// 	// 		that.updateStatus(3, 'RFI submitted to PD');
						// 	// 	},
						// 	// 	reject: () => {
						// 	// 		this.router.navigate(['/threshold']);
						// 	// 	}
						// 	//});
						// } else {
						// 	that.updateStatus(3, 'RFI submitted to PD', true);
						// }
						console.log(commodityNonZero);
						console.log(commodityNonZero);
						console.log(commodity);
						console.log(program);
					});
			},
			reject: () => {
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary: 'RFI/RFQ has not been Submit to PD.'
					});
				}, 30);
			}
		});
	}

	updateRFI() {
		const isSupplier = this.buyerSupplier === 'supplier';
		const comment = isSupplier
			? this.rfiRequest.rfiComments
			: this.rfiRequest.buyerComments;

		let rfiCost = this.getRFIAdd(
			this.rfiRequest.rfiPrevCost,
			this.toolingTotal
		);
		if (this.isToolingWholeOption === 'true') {
			rfiCost = this.rfiRequest.rfiCost;
		}

		this.rfiRequest.weightImpactFlag =
			this.isPartWeightImp === 'true' ? true : false;
		const pdComments = this.rfiRequest.pdComments;
		const internalComment = this.rfiRequest.internalComment;

		const weightComment = this.rfiRequest.weightComment;
		const weightQauntity = this.rfiRequest.weightQauntity;
		const weightFlag = this.rfiRequest.weightFlag;
		const weightImpactFlag = this.rfiRequest.weightImpactFlag;

		const rfi = {
			comment,
			rfiCost,
			pdComments,
			internalComment,
			weightComment,
			weightQauntity,
			weightFlag,
			weightImpactFlag
		};

		this.rfiRequestService
			.updateRFI(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum,
				rfi,
				isSupplier
			)
			.subscribe((response) => {
				console.log(response);
				//this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: 'RFI Successfully Submitted For Review'});
			});
	}

	isAllFieldsFilled(questions, type) {
		if ('Questions' === type && this.isPostFDJQuestionEnabled()) {
			for (const ques of questions) {
				if (ques.answer) {
					//do nothing

					//For multiple answer question
					if (
						ques.rfiType === 'RADIO,DATE' &&
						ques.answer === 'Yes'
					) {
						if (ques.answer1) {
							//do nothing
						} else {
							return false;
						}
					}
				} else {
					return false;
				}
			}
		}

		if ('Materials' === type) {
			for (const cost of this.materialCosts) {
				if (!cost.isTotal && cost.curRFI === 0) {
					return false;
				}
			}
		}

		if ('Commercials' === type) {
			for (const cost of this.commercialCosts) {
				if (!cost.isTotal && cost.curRFI === 0) {
					return false;
				}
			}
		}

		if ('LumpSum' === type) {
			for (const cost of this.commercialCosts) {
				if (cost.costBucket === 'ED&T paid in Piece Price') {
					if (!cost.lsIncludeFIn && !cost.lumpSum) {
						return false;
					}
				}
			}
		}
		if ('Toolings' === type) {
			if (this.toolingRecords.length === 0) {
				return false;
			}
			if (this.toolingRecords.length !== 0) {
				for (const toolingRecord of this.toolingRecords) {
					if (
						toolingRecord.cost === undefined ||
						toolingRecord.cost == null
					) {
						return false;
					}
				}
			}
		}
		return true;
	}

	updateRFIStatus() {
		if (this.rfiRequest.statusId === 5) {
			this.updateStatus(
				6,
				'RFI/RFQ request updated response required',
				false
			);
		} else {
			this.updateStatus(
				2,
				'RFI/RFQ request is in Acknowledgement status now',
				false
			);
		}
	}

	isBeginRFI(rfiRequest) {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let editAllowed = true;
		if (
			(apsRoles && apsRoles.includes('eDCM_Super_Buyer')) ||
			this.isPdEngg
		) {
			editAllowed = false;
		}

		this.beginRFI =
			editAllowed &&
			this.buyerSupplier === 'supplier' &&
			(rfiRequest.statusId === 1 || rfiRequest.statusId === 5);
		this.showCalculation =
			(this.buyerSupplier === 'supplier' && rfiRequest.statusId === 1) ||
			//|| (this.buyerSupplier === 'buyer' && (rfiRequest.statusId !== 3 && rfiRequest.statusId !== 7 && rfiRequest.statusId !== 8 && rfiRequest.statusId !== 9 && rfiRequest.statusId !== 10 && rfiRequest.statusId !== 5));
			(this.buyerSupplier === 'buyer' &&
				this.isBuyerOwnRfi() &&
				rfiRequest.statusId === 1 &&
				rfiRequest.statusId === 2 &&
				rfiRequest.statusId === 3 &&
				rfiRequest.statusId === 7 &&
				rfiRequest.statusId === 8 &&
				rfiRequest.statusId === 9 &&
				rfiRequest.statusId === 10 &&
				rfiRequest.statusId === 5);
	}

	isEditRFI() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let editAllowed = true;
		if (
			(apsRoles && apsRoles.includes('eDCM_Super_Buyer')) ||
			this.isPdEngg
		) {
			editAllowed = false;
		}

		return (
			editAllowed &&
			(this.rfiRequest.statusId === 2 ||
				this.rfiRequest.statusId === 6) &&
			this.buyerSupplier === 'supplier'
		);
	}

	isPdEnggRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('PD_Engineer') || apsRoles.includes('JV Engineer')
		);
	}

	isCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('CostEstimator') ||
			apsRoles.includes('JV Costestimator')
		);
	}

	isInternalCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const cdsid = sessionStorage.getItem('cdsid');
		const estimator = this.rfiRequest.costEstimator
			? this.rfiRequest.costEstimator.trim()
			: '';
		return (
			cdsid === estimator &&
			(apsRoles.includes('CostEstimator') ||
				apsRoles.includes('JV Costestimator'))
		);
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	isUploadBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const status = [3, 5, 7, 8, 9, 10, 17, 18];
		return (
			this.buyerSupplier === 'buyer' &&
			status.includes(this.rfiRequest.statusId)
		);
	}
	isSupplierReviewRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const status = [2, 3, 5, 7, 8, 9, 10, 18, 17];
		return (
			this.buyerSupplier === 'supplier' &&
			status.includes(this.rfiRequest.statusId)
		);
	}

	isUploadSupplierRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		//console.log(apsRoles);
		const status = [2, 6];
		return (
			this.buyerSupplier === 'supplier' &&
			status.includes(this.rfiRequest.statusId)
		);
	}

	isSupplierRole(apsRoles) {
		return apsRoles.includes('EDCM_UPDATE');
	}

	isShowReqAddTime() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let editAllowed = true;
		if (
			(apsRoles && apsRoles.includes('eDCM_Super_Buyer')) ||
			this.isPdEngg
		) {
			editAllowed = false;
		}

		return (
			editAllowed &&
			((this.buyerSupplier.toLowerCase() === 'supplier' &&
				(this.rfiRequest.statusId === 1 ||
					this.rfiRequest.statusId === 5 ||
					this.rfiRequest.statusId === 2 ||
					this.rfiRequest.statusId === 6)) ||
				(this.buyerSupplier.toLowerCase() === 'buyer' &&
					this.rfiRequest.statusId === 7) ||
				(this.buyerSupplier.toLowerCase() === 'buyer' &&
					this.rfiRequest.statusId === 18 &&
					(this.isBuyerOwnRfi() || this.isVaccationRfi())))
		);
	}

	isSubmittedToPD(rfiRequest) {
		this.submitToPDRFI =
			(rfiRequest.statusId === 7 &&
				(this.isBuyerOwnRfi() || this.isVaccationRfi())) ||
			(rfiRequest.statusId === 18 &&
				(this.isBuyerOwnRfi() || this.isVaccationRfi()));
	}

	isReSubmitRFI(rfiRequest) {
		this.reSubmitRFI =
			((rfiRequest.statusId === 7 || rfiRequest.statusId === 4) &&
				(this.isBuyerOwnRfi() || this.isVaccationRfi())) ||
			(rfiRequest.statusId === 18 &&
				(this.isBuyerOwnRfi() || this.isVaccationRfi()));
	}

	isBuyerComment() {
		return (
			(this.rfiRequest.statusId === 4 ||
				this.rfiRequest.statusId === 7 ||
				this.rfiRequest.statusId === 18) &&
			(this.isBuyerOwnRfi() || this.isVaccationRfi())
		);
	}

	isInternalComment() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		//console.log(apsRoles);
		return this.buyerSupplier.toLowerCase() !== 'supplier';
	}

	isPdEnggOrReadOnly() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('JV Engineer') ||
			apsRoles.includes('EDCM_VIEW_ONLY')
		);
	}
	isITAdmin() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles &&
			(apsRoles.includes('eDCM_IT_ADMIN') ||
				apsRoles.includes('eDCM_Super_Buyer'))
		);
	}

	isBuyerOwnRfi() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const buyer =
			this.buyerSupplier.toLowerCase() === 'buyer' &&
			!this.isPdEnggOrReadOnly();
		const userId = sessionStorage.getItem('userId');
		const isITAdmin =
			apsRoles &&
			(apsRoles.includes('eDCM_IT_ADMIN') ||
				apsRoles.includes('eDCM_Super_Buyer'));
		return (
			buyer &&
			(isITAdmin ||
				(this.rfiRequest.buyerCode != null &&
					this.rfiRequest.buyerCode.trim().toLowerCase() ===
						userId.trim().toLowerCase()))
		);
	}

	isEmailContains(record) {
		const email = sessionStorage.getItem('emailAddress').toLowerCase();
		const existsEmail = [
			record.supplier?.toLowerCase(),
			record.secondSupplier?.toLowerCase(),
			record.suppVaccationCode?.toLowerCase(),
			record.secSuppVaccationCode?.toLowerCase()
		];
		return existsEmail.includes(email);
	}

	/*#### Code Related to Supplier own RFI organization Functionlaity ###*/
	isSupplierOwnRfi() {
		const supplier = this.buyerSupplier.toLowerCase() === 'supplier';
		const userId = sessionStorage.getItem('emailAddress');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const isITAdmin =
			apsRoles &&
			(apsRoles.includes('eDCM_IT_ADMIN') ||
				apsRoles.includes('eDCM_Super_Buyer'));

		const ownRfi =
			supplier && (isITAdmin || this.isEmailContains(this.rfiRequest));
		// || (this.rfiRequest.supplier != null &&
		// 	this.rfiRequest.supplier.trim().toLowerCase() ===
		// 		userId.trim().toLowerCase()));
		return ownRfi;
	}

	isVaccationRfi() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const buyer =
			this.buyerSupplier.toLowerCase() === 'buyer' && !this.isPdEngg;
		const userId = sessionStorage.getItem('userId');

		const isITAdmin =
			(apsRoles && apsRoles.includes('eDCM_IT_ADMIN')) ||
			apsRoles.includes('eDCM_Super_Buyer');
		return (
			buyer &&
			(isITAdmin ||
				(this.rfiRequest.vaccationCode != null &&
					this.rfiRequest.vaccationCode.trim().toLowerCase() ===
						userId.trim().toLowerCase()))
		);
	}

	getStatusString() {
		switch (this.rfiRequest.statusId) {
			case 8:
				if (this.buyerSupplier === 'supplier') {
					this.statusColor = '#000000';
				} else {
					this.statusColor = '#00c200';
				}
				break;
			case 17:
				if (this.buyerSupplier === 'supplier') {
					this.statusColor = '#000000';
				} else {
					this.statusColor = '#00c200';
				}
				break;
			case 9:
				if (this.buyerSupplier === 'supplier') {
					this.statusColor = '#000000';
				} else {
					this.statusColor = '#c20000';
				}
				break;
			case 10:
				if (this.buyerSupplier === 'supplier') {
					this.statusColor = '#000000';
				} else {
					this.statusColor = '#c20000';
				}
				break;
			case 3:
			case 4:
			case 7:
				this.statusColor = '#000000';
				break;
			case 18:
				this.statusColor = '#000000';
				break;
			case 2:
				this.statusColor = '#000000';
				break;
			case 1:
				this.statusColor = '#000000';
				break;
		}

		return this.buyerSupplier === 'supplier'
			? this.rfiRequest.supplierStatus
			: this.rfiRequest.buyerStatus;
	}

	getRFIDiff(prevRFI, curRFI) {
		const diff = curRFI - prevRFI;
		const amount = parseFloat(Math.abs(diff).toFixed(5)).toLocaleString(
			'en-US',
			{
				minimumFractionDigits: 5,
				maximumFractionDigits: 5
			}
		);

		return (
			(Math.sign(diff) === 1 ? '-' : Math.sign(diff) === -1 ? '+' : '') +
			amount
		);
	}

	getRFIDiffSign(prevRFI, curRFI) {
		const diff = curRFI - prevRFI;
		return Math.sign(diff) === 1 ? -1 : Math.sign(diff) === -1 ? 1 : 0;
	}

	getRFIDelta() {
		let diff = 0;
		if (this.isToolingWholeOption !== 'true') {
			diff = this.toolingTotal;
		} else {
			diff = this.rfiRequest.rfiCost - this.rfiRequest.rfiPrevCost;
		}

		const amount = parseFloat(Math.abs(diff).toFixed(0)).toLocaleString(
			'en-US'
		);

		return (
			(Math.sign(diff) === -1 ? '-' : Math.sign(diff) === 1 ? '+' : '') +
			amount
		);
	}

	getRFIDeltaSign() {
		let diff = 0;
		if (this.isToolingWholeOption !== 'true') {
			diff = this.toolingTotal;
		} else {
			diff = this.rfiRequest.rfiCost - this.rfiRequest.rfiPrevCost;
		}
		return Math.sign(diff) === 1 ? 1 : Math.sign(diff) === -1 ? -1 : 0;
	}

	getWeightDelta() {
		const diff =
			this.rfiRequest.weightQauntity - this.rfiRequest.rfiPrevWeight;
		const amount = parseFloat(Math.abs(diff).toFixed(5)).toLocaleString(
			'en-US',
			{
				minimumFractionDigits: 5,
				maximumFractionDigits: 5
			}
		);
		return (
			(Math.sign(diff) === -1 ? '-' : Math.sign(diff) === 1 ? '+' : '') +
			amount
		);
	}

	getRFIAdd(prevRFI, totaTooling) {
		const add: number = Number(totaTooling) + Number(prevRFI);
		const result = add.toFixed(0);
		const amount = parseFloat(result).toLocaleString('en-US');
		return amount;
	}

	get4Decimal(prevRFI) {
		const amount = (Math.sign(prevRFI) * Math.abs(prevRFI)).toFixed(5);
		return parseFloat(amount).toLocaleString('en-US', {
			minimumFractionDigits: 5,
			maximumFractionDigits: 5
		});
	}

	getDecimal(cost) {
		return (Math.sign(cost) * Math.abs(cost)).toFixed(5);
	}

	getRFIlumpDiff(prevRFI, curRFI) {
		const diff = curRFI - prevRFI;
		//const result = (Math.sign(diff) === 1 ? '-' : (Math.sign(diff) === -1 ? '+' : '')) + Math.abs(diff).toFixed(0);
		const amount = parseFloat(Math.abs(diff).toFixed(0)).toLocaleString(
			'en-US'
		);
		return (
			(Math.sign(diff) === 1 ? '-' : Math.sign(diff) === -1 ? '+' : '') +
			amount
		);
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	decimalFilter(event): boolean {
		const reg = /^-?\d*(\.\d{0,5})?$/;
		const value = event.target.value + String.fromCharCode(event.charCode);
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode === 46) {
			return reg.test(value);
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	isPostFDJQuestionEnabled() {
		return this.rfiRequest.postfdjF;
	}

	onSelectChange(event, rowData) {
		console.log(event);
		if (event === 'No') {
			rowData.answer1 = '';
		}
	}

	updateDate(rowData, ans) {
		if (ans === 'answer1') {
			rowData.answer1 = moment(rowData.answer1).format('MM/DD/YYYY');
		} else {
			rowData.answer = moment(rowData.answer).format('MM/DD/YYYY');
		}
	}

	unSelectValue(rowData, type) {
		if (type === 'lsIncludeFIn') {
			rowData.lsIncludeFIn = null;
			/*rowData.curRFI = 0.0000;*/
		} else {
			rowData.lumpSum = null;
			/*rowData.lumpSumA = 0.0000;*/
		}

		this.isUnselectClick = true;
	}

	onQuestionRowEditInit(question, cloned) {
		cloned[question.lookupId] = question;
	}

	onQuestionRowEditSave(question, cloned) {
		delete cloned[question.lookupId];
		//this.updateTotalCost(costs1, costs2);
	}

	onQuestionRowEditCancel(question, cloned, index: number) {
		this.rfiQuestions[index] = cloned[question.lookupId];
		delete cloned[question.lookupId];
	}

	onQuestionSave() {
		for (const ques of this.rfiQuestions) {
			if (
				(ques.rfiType === 'RADIO,DATE' && ques.answer === 'Yes') ||
				ques.rfiType === 'DATE,DATE' ||
				ques.rfiType === 'TEXT,DATE'
			) {
				ques.answer =
					ques.answer + '|' + (ques.answer1 ? ques.answer1 : '');
			}
		}
		this.rfiQuestionRequestService
			.saveQuestions(
				this.rfiQuestions,
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			)
			.subscribe((response) => {
				//this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: 'RFI questions is updated'});
				this.populateQuestions(this.rfiQuestions);
				console.log(response);
			});
		this.selectedMode = 'View';
	}

	populateQuestions(rfiQuestions) {
		for (const ques of rfiQuestions) {
			if (
				ques.rfiType === 'RADIO,DATE' ||
				ques.rfiType === 'DATE,DATE' ||
				ques.rfiType === 'TEXT,DATE'
			) {
				const ans = ques.answer;
				ques.answer = ans;
				if (ans) {
					ques.answer = ans.split('|')[0];
					if (ans.split('|').length === 2) {
						ques.answer1 = ans.split('|')[1];
					} else {
						ques.answer1 = '';
					}
				} else {
					ques.answer = '';
				}
			}
		}

		return rfiQuestions;
	}

	saveCosts() {
		let lumpSumCost = 0;
		let prevRfiLumpSumA = 0;
		for (const result of this.commercialCosts) {
			if (result.costBucket === 'ED&T paid in Piece Price') {
				lumpSumCost = result.lumpSumA;
				prevRfiLumpSumA = result.prevLumpSumA;
			}
			console.log(lumpSumCost);
			console.log(prevRfiLumpSumA);
		}
		const costs = this.materialCosts.concat(this.commercialCosts);
		//if (this.prevSubMatTotCurRFI !== this.subMatTotCurRFI
		//|| this.prevSubComTotCurRFI !== this.subComTotCurRFI
		//|| this.prevLumpSumCost !== lumpSumCost) {
		this.costChange = true;
		costs.forEach((cost) => {
			cost.curRFI = parseFloat(this.getDecimal(cost.curRFI));
			cost.lumpSumA = parseFloat(this.getDecimal(cost.lumpSumA));
		});

		this.rfiCostRequestService
			.saveCosts(
				costs,
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum,
				this.rfiRequest.prevRfiPrefix,
				this.rfiRequest.prevRfiNum
			)
			.subscribe((response) => {
				console.log(response);
				//this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: 'RFI record is updated'});
			});
		//}
	}

	getToolingCost() {
		let toolingCost = 0;
		if (this.isToolingWholeOption !== 'true') {
			toolingCost = this.rfiRequest.rfiPrevCost + this.toolingTotal;
		}

		if (this.isToolingWholeOption === 'true') {
			toolingCost = this.rfiRequest.rfiCost;
		}

		return toolingCost;
	}

	saveToolings() {
		if (this.isToolingWholeOption === 'true') {
			this.resetToolingItems();
		}

		if (this.prevToolingCost !== this.getToolingCost()) {
			this.costChange = true;
			this.rfiToolingRequestService
				.saveToolings(
					this.toolingRecords,
					this.rfiRequest.rfiPrefix,
					this.rfiRequest.rfiNum
				)
				.subscribe((response) => {
					console.log(response);
					//this.messageService.add({life: 300000, severity: 'success', summary: 'Success', detail: 'Toolings records updated'});
				});

			// this.rfiHistory = {...this.rfiHistory,
			// 	toolingPrice: this.getToolingCost(),
			// 	toolingPriceDelta: (this.getToolingCost() - this.rfiRequest.rfiPrevCost)
			// };
		}
	}

	onTabChange(event) {
		this.tabIndex = event.index;
	}

	isCostTab() {
		return this.tabIndex === 0 || this.tabIndex === 1;
	}

	isEditMode() {
		return this.selectedMode === 'Edit' && !this.isCostEstimatorRole();
	}

	isSupplierWarningRequired() {
		const beginSupplier = this.rfiRequest.beginSupplier
			? this.rfiRequest.beginSupplier.toLowerCase()
			: null;
		return beginSupplier;
	}

	isCopiedWarningRequired() {
		const userId = sessionStorage.getItem('emailAddress');
		const beginSupplier = this.rfiRequest.beginSupplier
			? this.rfiRequest.beginSupplier.toLowerCase()
			: null;
		return (
			beginSupplier &&
			userId !== beginSupplier &&
			this.isShowTimeDisClaimer()
		);
	}

	downloadAttachment(fileName) {
		let cmfDCR = this.rfiRequest.cmfDCR.trim();
		let sourceSystem = 'e3p';
		if (cmfDCR.startsWith('C')) {
			cmfDCR = cmfDCR.split('-')[1].trim();
			sourceSystem = 'bom';
		}
		this.rfiRequestService
			.downloadAttachment(
				cmfDCR,
				this.rfiRequest.partNumber.trim(),
				fileName,
				sourceSystem
			)
			.subscribe(
				(data) => {
					this.downloadFile(data, fileName);
				},
				(error: HttpErrorResponse) => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary: 'Error',
						detail: 'Unable to download attachment.'
					});
					console.log(error);
				}
			);
	}

	isBreakDownDocumentsAvailable() {
		return (
			this.isBuyerRole() ||
			this.isSupplierRole(
				JSON.parse(sessionStorage.getItem('apsRoles'))
			) ||
			this.isITAdmin()
		);
	}

	downloadRFIFile(fileName) {
		this.rfiDocumentService
			.downloadDocument(fileName)
			.subscribe((response) => {
				const blob = new Blob([response]);
				const downloadUrl = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = downloadUrl;
				a.download = fileName;
				a.click();
			});
	}

	downloadFile(data, fileName) {
		if (data.size > 0) {
			const blob = new Blob([data]);
			const downloadUrl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = fileName;
			a.click();
		} else {
			this.messageService.add({
				life: 300000,
				severity: 'warn',
				summary: 'Error',
				detail: 'File no longer available.'
			});
		}
	}

	generatePdf() {
		const costBucket = this.commercialCosts.filter(
			(cost) => cost.costBucket === 'ED&T paid in Piece Price'
		)?.[0];
		const isLumpSumSelected = costBucket?.lumpSum
			? true
			: costBucket?.lsIncludeFIn
				? false
				: undefined;
		let lsIncludeFInValue = costBucket?.lsIncludeFIn
			? costBucket?.lsIncludeFIn === 'true'
				? true
				: false
			: undefined;
		if (isLumpSumSelected == true) {
			lsIncludeFInValue = costBucket?.lumpSum === 'true' ? true : false;
		}
		if (isLumpSumSelected == undefined) {
			lsIncludeFInValue = undefined;
		}
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let fileNameRole;

		if (apsRoles.includes('eDCM_IT_ADMIN')) {
			fileNameRole =
				this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
		} else {
			fileNameRole = apsRoles;
		}
		this.pdfGenerator.generatePdf(
			this.rfiRequest,
			this.getStatusString(),
			this.materialCosts,
			this.commercialCosts,
			this.isToolingWholeOption,
			this.toolingTotal,
			this.totCurvRFI,
			this.totPrevRFI,
			this.rfiHistoryList,
			this.buyerSupplier,
			this.rfqText,
			isLumpSumSelected,
			lsIncludeFInValue,
			fileNameRole
		);
	}
	generateExcel() {
		const costBucket = this.commercialCosts.filter(
			(cost) => cost.costBucket === 'ED&T paid in Piece Price'
		)?.[0];
		const isLumpSumSelected = costBucket?.lumpSum
			? true
			: costBucket?.lsIncludeFIn
				? false
				: undefined;
		let lsIncludeFInValue = costBucket?.lsIncludeFIn
			? costBucket?.lsIncludeFIn === 'true'
				? true
				: false
			: undefined;
		if (isLumpSumSelected == true) {
			lsIncludeFInValue = costBucket?.lumpSum === 'true' ? true : false;
		}
		if (isLumpSumSelected == undefined) {
			lsIncludeFInValue = undefined;
		}
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		let fileNameRole;

		if (apsRoles.includes('eDCM_IT_ADMIN')) {
			fileNameRole =
				this.buyerSupplier === 'buyer' ? 'Buyer' : 'Supplier';
		} else {
			fileNameRole = apsRoles;
		}
		this.excelGenerator.generateExcel(
			this.rfiRequest,
			this.getStatusString(),
			this.materialCosts,
			this.commercialCosts,
			this.isToolingWholeOption,
			this.toolingTotal,
			this.totCurvRFI,
			this.totPrevRFI,
			this.rfiHistoryList,
			this.buyerSupplier,
			this.rfqText,
			isLumpSumSelected,
			lsIncludeFInValue,
			fileNameRole
		);
	}

	private addWeekdays(date, days) {
		date = moment(date); // use a clone
		while (days > 0) {
			date = date.add(1, 'days');
			// decrease "days" only if it's a weekday.
			if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
				days -= 1;
			}
		}
		return date;
	}

	private getSundayMidNightTime(time) {
		const isWeekend = time.isoWeekday() === 6 || time.isoWeekday() === 7;
		if (isWeekend) {
			const daysForFridayCalc = time.isoWeekday() === 7 ? 0 : 1;
			time = time.endOf('day').add(daysForFridayCalc, 'days');
		}

		return moment.utc(time);
	}

	private calculateTimerTime(rfiRequest) {
		this.negativeTimer = true;
		const isSupplier = this.buyerSupplier === 'supplier' ? true : false;
		let days: number;
		if (this.selectedType === 'RFQ') {
			days = isSupplier ? 5 : 2;
		} else if (this.selectedType === 'RFI') {
			days = isSupplier ? 3 : 2;
		}

		if (rfiRequest.responseDate) {
			const isWeekend =
				moment.utc(rfiRequest.responseDate).isoWeekday() === 6 ||
				moment.utc(rfiRequest.responseDate).isoWeekday() === 7;
			let endTime = this.addWeekdays(
				moment.utc(rfiRequest.responseDate),
				days
			);
			if (isWeekend) {
				endTime = this.getSundayMidNightTime(
					moment(rfiRequest.responseDate)
				);
				endTime = this.addWeekdays(moment.utc(endTime), days);
			}
			const startTime = this.getSundayMidNightTime(moment(new Date()));

			let daysDiff = endTime.diff(moment.utc(startTime), 'days');
			if (daysDiff > days) {
				daysDiff = daysDiff - 2;
			}

			const dif = moment.duration(endTime.diff(startTime));
			const hours = daysDiff * 24 + dif.hours();
			this.timer = [hours, dif.minutes(), dif.seconds()].join(':');

			this.negativeTimer =
				endTime.isBefore(startTime) || endTime === startTime;

			if (this.isShowReqAddTime() && this.negativeTimer) {
				this.modeType = [];
			}
		}
	}

	private calculateTimerTimeBack(rfiRequest) {
		if (rfiRequest.responseDate) {
			const endTime = moment.utc(rfiRequest.responseDate).add(3, 'days');
			const startTime = moment.utc(new Date());
			const dif = moment.duration(endTime.diff(startTime));

			const hours = dif.days() * 24 + dif.hours();
			this.timer = [hours, dif.minutes(), dif.seconds()].join(':');

			this.negativeTimer = endTime.isBefore(startTime);

			if (this.isEditRFI() && this.negativeTimer) {
				this.modeType = [
					{
						title: 'View',
						label: 'View',
						value: 'View',
						icon: 'fa fa-eye'
					}
				];
			}
		}
	}

	private requestAdditionalTime() {
		this.rfiRequestService
			.updateExtReqCount(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum,
				this.buyerSupplier === 'supplier'
			)
			.subscribe((response) => {
				/*setTimeout(() => {

					this.messageService.add({
						life: 300000, severity: 'success',
						summary:
							'Extra time has been allocated successfully to RFI request.'
					});
				}, 30000);*/

				this.rfiRequest = response;

				if (this.isShowReqAddTime()) {
					this.calculateTimerTime(this.rfiRequest);
				}

				if (this.isEditRFI() && !this.negativeTimer) {
					this.updateModType();
				}
			});
	}

	private retriveQuestions(rfiId) {
		this.rfiQuestionRequestService
			.findByRfiHeaderId(rfiId)
			.subscribe((rfiQuestions) => {
				this.rfiQuestions = this.populateQuestions(rfiQuestions);
				this.isLoader = false;
			});
	}

	private retriveAllCosts(rfiId) {
		this.rfiCostRequestService
			.findByRfiHeaderId(rfiId, this.rfiRequest.prevRfiNum)
			.subscribe((responseCosts) => {
				const rfiCost = responseCosts;

				this.materialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Material'
				);
				this.commercialCosts = rfiCost.filter(
					(res) => res.rfiType === 'Commercial'
				);

				/*this.materialCosts.map(res => {
				res.curRFI.toFixed(4);
				res.preRFI.toFixed(4);
			});

			this.commercialCosts.map(res => {
				res.curRFI.toFixed(4);
				res.preRFI.toFixed(4);
			});*/

				this.updateTotalCost(true);
				this.isLoader = false;
			});
	}

	private retriveTooling(rfiId) {
		this.rfiToolingRequestService
			.findByRfiHeaderId(
				this.rfiRequest.rfiPrefix,
				this.rfiRequest.rfiNum
			)
			.subscribe((records) => {
				this.toolingRecords = records;
				if (this.toolingRecords && this.toolingRecords.length > 0) {
					this.isToolingWholeOption = 'false';
				}
				this.getTotalTooling();
			});
		console.log(rfiId);
	}

	private retriveWeight() {
		this.isPartWeightImp = this.rfiRequest.weightImapactFlag
			? 'true'
			: 'false';
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe((params) => {
			this.buyerSupplier = params.get('buyerSupplier');
			this.mode = params.get('mode');
			this.rfiId = params.get('id');
			this.searchRfi = params.get('searchRfi');
			this.back = params.get('back');
			this.selectedType = params.get('selectedType');
			if (params.get('status')) {
				this.status = params.get('status');
			}

			if (params.get('program')) {
				this.program = params.get('program');
			}
			this.loadLocalStorageForRfi();
			this.loadRFIData();
		});
	}
	loadLocalStorageForRfi() {
		if (this.rfiId) {
			const storageKey = `isPiecePriceEstimateSaved-${this.rfiId}`;
			const storageKeyTooling = `isProductionToolingEstimateSaved-${this.rfiId}`;
			if (localStorage.getItem(storageKey) === 'true') {
				this.isPiecePriceEstimateSaved = true;
			}
			if (localStorage.getItem(storageKeyTooling) === 'true') {
				this.isProductionToolingEstimateSaved = true;
			}
		}
	}

	saveLocalStorageForRfi() {
		if (this.rfiId) {
			const storageKeyPiecePrice = `isPiecePriceEstimateSaved-${this.rfiId}`;
			const storageKeyTooling = `isProductionToolingEstimateSaved-${this.rfiId}`;
			localStorage.setItem(
				storageKeyPiecePrice,
				this.isPiecePriceEstimateSaved.toString()
			);
			localStorage.setItem(
				storageKeyTooling,
				this.isProductionToolingEstimateSaved.toString()
			);
		}
	}
	private validationCheck() {
		this.buddyBuyerMap['buddyBuyer'] =
			this.rfiRequest.buyerCode !== undefined
				? this.rfiRequest.buyerCode.trim()
				: '';
		this.manualRfiRequestService
			.validateBuyer(this.buddyBuyerMap)
			.subscribe((response) => {
				if (response.status && response.status === 'Success') {
					this.validationMessage =
						'CDSID does not have a “BUYER” or “JV Buyer” role.';
					this.isValidBuyer = false;
				} else {
					this.isValidBuyer = true;
				}
			});
	}

	private calculateTotalCost(costs) {
		let subTotPrevRFI = 0;
		let subTotCurRFI = 0;
		for (const result of costs) {
			if (result.costBucket !== 'Subtotal Piece cost') {
				subTotPrevRFI += result.prevRFI;
				subTotCurRFI += result.curRFI;
			} else {
				result['prevRFI'] = subTotPrevRFI;
				result['curRFI'] = subTotCurRFI;
			}
		}
	}

	private updateTotalCost(initialLoad) {
		this.totPrevRFI = 0;
		this.totCurvRFI = 0;
		let matTotPrevRFI = 0;
		let matTotCurRFI = 0;
		let comTotPrevRFI = 0;
		let comTotCurRFI = 0;

		for (const result of this.materialCosts) {
			if (!result.isTotal) {
				matTotPrevRFI += +result.prevRFI;
				matTotCurRFI += +result.curRFI;
			}

			if (result.isTotal) {
				result.prevRFI = matTotPrevRFI;
				result.curRFI = matTotCurRFI;
			}
		}

		this.subMatTotCurRFI = matTotCurRFI;
		this.subMatTotPrevRFI = matTotPrevRFI;
		if (initialLoad) {
			this.materialCosts.push({
				costBucket: 'Piece Price Subtotal',
				prevRFI: matTotPrevRFI,
				curRFI: matTotCurRFI,
				isTotal: true,
				lookupId: -1
			});

			this.prevSubMatTotCurRFI = matTotCurRFI;
			this.prevSubMatTotPrevRFI = matTotPrevRFI;
		}

		for (const result of this.commercialCosts) {
			if (!result.isTotal) {
				comTotPrevRFI += +result.prevRFI;
				comTotCurRFI += +result.curRFI;
			}

			if (result.isTotal) {
				result.prevRFI = comTotPrevRFI;
				result.curRFI = comTotCurRFI;
			}
		}

		this.subComTotCurRFI = comTotCurRFI;
		this.subComTotPrevRFI = comTotPrevRFI;
		if (initialLoad) {
			this.commercialCosts.push({
				costBucket: 'Commercial Adjustment Total',
				prevRFI: comTotPrevRFI,
				curRFI: comTotCurRFI,
				isTotal: true,
				lookupId: -1
			});
			this.prevSubComTotCurRFI = comTotCurRFI;
			this.prevSubComTotPrevRFI = comTotPrevRFI;

			for (const result of this.commercialCosts) {
				if (result.costBucket === 'ED&T paid in Piece Price') {
					this.prevLumpSumCost = result.lumpSumA;
				}
			}
		}

		this.totPrevRFI = matTotPrevRFI + comTotPrevRFI;
		this.totCurvRFI = matTotCurRFI + comTotCurRFI;
	}

	canInteranlCostDetailsEdit(flag) {
		const statusIds = [1, 2, 3, 4, 5, 6, 7, 15, 12, 17, 18];
		return (
			this.buyerSupplier === 'buyer' &&
			flag &&
			statusIds.includes(this.rfiRequest.statusId)
		);
	}
	isEDCMViewOnlyRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('EDCM_VIEW_ONLY');
	}
	toggleInteranlCostDetailsEdit(value) {
		this.interanlCostDetailsEdit = value;
	}
	getInterCostDetailsUpdateString() {
		let updateDateTime = new Date();
		if (this.rfiHistoryInternalCostList.length > 0)
			updateDateTime =
				this.rfiHistoryInternalCostList[
					this.rfiHistoryInternalCostList.length - 1
				].updateDate;
		const updateDate = moment(updateDateTime).format('MM/DD/YYYY HH:mm');
		return (
			'Updated By' +
			'-' +
			this.rfiRequest.updateIntCstDetailsBy +
			'--' +
			this.rfiRequest.role +
			'--' +
			updateDate
		);
	}
	saveInternalCotDetails() {
		this.toggleInteranlCostDetailsEdit(false);
		/*this.isPiecePriceEstimateEdited = true;
		this.isPiecePriceEstimateCurrencyEdited = true;
		this.isToolingEstimateEdited = true;
		this.isToolingEstimateCurrencyEdited = true;
		this.saveLocalStorageForRfi();*/
		this.rfiRequest.role = JSON.parse(
			sessionStorage.getItem('apsRoles')
		)[0];
		this.rfiRequest.createdBy = sessionStorage.getItem('userId');
		this.rfiRequest.updateIntCstDetailsBy =
			sessionStorage.getItem('userId');
		this.rfiRequest.partCostTarget = Number(this.rfiRequest.partCostTarget);
		this.rfiRequest.pdMatCostEstimate = Number(
			this.rfiRequest.pdMatCostEstimate
		);
		this.rfiRequest.e3pMatCostEstimate = Number(
			this.rfiRequest.e3pMatCostEstimate
		);
		this.rfiRequest.toolingCostTarget = Number(
			this.rfiRequest.toolingCostTarget
		);
		this.rfiRequest.pdToolingEstimate = Number(
			this.rfiRequest.pdToolingEstimate
		);
		this.rfiRequest.e3pToolingEstimate = Number(
			this.rfiRequest.e3pToolingEstimate
		);
		this.rfiRequest.rfiPrevCost = Number(this.rfiRequest.rfiPrevCost);
		this.rfiRequest.updateIntCstDetailsDate = moment();
		this.rfiRequest.rfiCost = Number(this.rfiRequest.rfiCost);
		this.rfiRequestService
			.saveRFIInternalCostDetails(this.rfiRequest)
			.subscribe((response) => {
				if (response) {
					this.isPiecePriceEstimateSaved =
						response.isproposedLocalCost;
					this.isProductionToolingEstimateSaved =
						response.isproposedToolCost;
					this.saveLocalStorageForRfi();
					if (
						response.isproposedLocalCost ||
						response.isproposedToolCost
					) {
						setTimeout(() => {
							this.messageService.add({
								life: 300000,
								severity: 'success',
								summary:
									'RFI/RFQ Internal Cost details has been saved.'
							});
							this.fetchRFIHeaderData();
						}, 30);
					}
					this.getRFIHistoryInternalCostList(
						this.rfiRequest.rfiPrefix,
						this.rfiRequest.rfiNum
					);
				}
			});
	}

	cancelInternalCostDetails() {
		this.toggleInteranlCostDetailsEdit(false);
		if (this.mode === 'edit') {
			this.isLoader = true;
			this.fetchRFIHeaderData();
		} else {
			this.rfiRequest.postfdjF = true;
			this.initializeRFICosts(this.rfiRequest);
			this.retriveAllCosts(this.rfiRequest.rfiNum);
			this.setDefaultCDSId();
		}
		this.getRFIHistoryInternalCostList(
			this.rfiRequest.rfiPrefix,
			this.rfiRequest.rfiNum
		);
		this.saveLocalStorageForRfi();
	}
	private initializeRFICosts(rfiRequest) {
		rfiRequest.partCostTarget = this.get4Decimal(0);
		rfiRequest.partCostTargetCur = 'USD';
		rfiRequest.pdMatCostEstimate = this.get4Decimal(0);
		rfiRequest.pdMatCostEstimateCur = 'USD';
		rfiRequest.e3pMatCostEstimate = this.get4Decimal(0);
		rfiRequest.e3pMatCostEstimateCur = 'USD';
		rfiRequest.toolingCostTarget = this.get4Decimal(0);
		rfiRequest.toolingCostTargetCur = 'USD';
		rfiRequest.pdToolingEstimate = this.get4Decimal(0);
		rfiRequest.pdToolingEstimateCur = 'USD';
		rfiRequest.e3pToolingEstimate = this.get4Decimal(0);
		rfiRequest.e3pToolingEstimateCur = 'USD';
		rfiRequest.rfiComments = '';
		rfiRequest.buyerComments = '';
		rfiRequest.pdComments = '';
		rfiRequest.internalComment = '';
		rfiRequest.rfiPrevCost = this.get4Decimal(0);
		rfiRequest.rfiCost = this.get4Decimal(0);
	}
	private setDefaultCDSId() {
		if (this.isBuyerRole()) {
			this.rfiRequest.buyerCode = sessionStorage.getItem('userId');
		}

		if (this.isPdEnggRole()) {
			this.rfiRequest.drCDSID = sessionStorage.getItem('userId');
		}
	}
}

export const emptyRfiRequest = {
	program: '',
	partNumDisplay: '',
	engineeringCommodityName: '',
	partDesc: '',
	changeDescription: '',
	moduleName: '',
	moduleType: '',
	drCDSID: '',
	buyerCDSID: '',
	gsdb: '',
	supplierResponded: ''
};
